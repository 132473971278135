@import '../../variables';

.thinAccordion {
  :global .accordion-item {
    .accordion-header {
      button {
        padding: 0;
      }
    }
  }
}

.narrative {
  padding-left: 20px
}

.bad {
  color: $ljiRed;
}

.gpa {

}

.asOfDate {
  font-size: 12px;
  font-style: italic;
}

.noSponsors {
  color: $ljiRed;
}