.notificationCount {
  position: absolute;
  right: -100%;
  top: -30%;
  font-size: 60%;
  padding: .2em;
  border-radius: 999px;
  line-height: .75em;
  color: white;
  background: orangered;
  text-align: center;
  min-width: 2em;
  font-weight: bold;
}