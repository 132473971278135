@import './customize-bootstrap.scss';
@import './customize-ag-grid.scss';

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.text {
  color: white;
}

.Childlight-Dropdown-Link {
  text-decoration: none;
  color: inherit;
}