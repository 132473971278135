@import '../../variables';

.good {
  color: green;
}

.bad {
  color: $ljiRed;
}

.dueLifeSkillIcon {
  margin-left: 10px;
  color: $ljiRed;
}


.asOfDate {
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
}

.lastGuidanceSession {
  padding-left: 20px;
  font-size: 13px;
  font-style: italic;
}