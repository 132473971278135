.wasRequired {
  color: green;
}

// Similar to asOfDate classes
.onDate {
  font-size: 12px;
  font-style: italic;
  // Make it slightly lighter but keep green as green
  opacity: 0.8;
}

.editLink {
  color: darkgrey;
}