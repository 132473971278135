@import "../../App";

.Main-Navbar {
  justify-content: end;
}
.localHeaderbar {
  background-color: green !important;
}
.stagingHeaderbar {
  background-color: goldenrod !important;
}
.prodHeaderbar {
//  Default coloring
}
.unknownEnvHeaderbar {
  background-color: mediumpurple !important;
}

.App-link {
  text-decoration: none;
  padding: 0 5px;
}
