@import '../../variables';

.lowRisk {
  color: green;
}

.normalRisk {
  color: blue;
}

.concerningRisk {
  color: orangered;
}

.highRisk {
  color: red;
}