@import '../../variables';

.skippedIcon {
  color: grey;
}

.skippedText {
  font-style: italic;
}

.overdueIcon {
  color: $ljiRed
}

.overdueText {
  color: $ljiRed
}

.receivedIcon {
  color: green;
}

.receivedText {
}

// No upcoming icon for now
.upcomingText {
  font-style: italic;
}