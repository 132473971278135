@import '../../variables';

.good {
  color: green;
}

.bad {
  color: $ljiRed;
}

.dueTrainingIcon {
  margin-left: 10px;
  color: $ljiRed;
}


.asOfDate {
  font-size: 12px;
  font-style: italic;
}