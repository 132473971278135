@import '../../variables';

.good {
  color: green;
}

.bad {
  color: $ljiRed;
}

.dueVaccinationIcon {
  margin-left: 10px;
  color: $ljiRed;
}