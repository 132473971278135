.feedbackMessagesWrapper {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.feedbackToast {
  /* horizontally center */
  margin: auto;
  min-width: 300px;
}
