// Bootstrap overrides/tweaks (see variables in node_modules/bootstrap/scss/_variables.scss)
@import 'variables';

// change the theme
$danger: $ljiRed;

@import '~bootstrap/scss/bootstrap';

.form-label {
  font-weight: bold;
}

.btn[disabled] {
  color: #a9a9a9;
  background-color: #e1e1e1;
}