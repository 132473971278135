@import '../../variables';

.ended{
  color: darkgrey;
  font-style: italic;
}

.noExtracurriculars{
  font-style: italic;
  color: $ljiRed;
}

.editLink {
  color: darkgrey;
}