.heading{
    text-align: right;
}

.dataLabel{
    float: right;
    text-align: right;
}

.dataDisplay{
    font-weight: bold;
}

.periodText{
    font-style: italic;
    font-size: 10px;
    line-height: 40px;
}