.container {
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "percent-area letter-area"
    ".    gpa-area";
}

.percent {
  grid-area: percent-area;

  text-align: start;
  padding-left: 5px;
}

.letter {
  grid-area: letter-area;

  text-align: end;
  padding-right: 5px;
}

.gpa {
  grid-area: gpa-area;

  text-align: end;
  padding-right: 5px;
  background-color: #e1e1e1
}