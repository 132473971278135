@import '../../variables';

.zscoreLevel {
  text-transform: capitalize;
}

.asOfDate {
  font-size: 12px;
  font-style: italic;
}

.good {
  color: green;
}

.bad {
  color: $ljiRed;
}